<template lang="">
  <IAmOverlay :loading="isLoading">
    <BRow>
      <BCol cols="2">
        <div class="d-flex flex-column gap-3 p-2">
          <BButton
            pill
            variant="airline"
            class="w-100"
            :disabled="isLoading"
            @click="onExportPDFHandle"
          >
            {{ $t('invoice.btn.exportPDF') }}
          </BButton>
          <BButton
            pill
            variant="airline"
            class="w-100"
            :disabled="!BUTTON_ACTION_PERMISSIONS?.[invoiceData?.invoiceStatus]?.[invoiceData?.releaseStatus]?.EDIT || isLoading || !companyId"
            @click="handleEditInvoice"
          >
            {{ $t('invoice.btn.edit') }}
          </BButton>
          <BButton
            pill
            class="w-100"
            variant="airline"
            :disabled="!BUTTON_ACTION_PERMISSIONS?.[invoiceData?.invoiceStatus]?.[invoiceData?.releaseStatus]?.SEND_EMAIL || isLoading || !companyId"
            @click="onSendEmailHandle"
          >
            {{ $t('invoice.btn.sendEmail') }}
          </BButton>
          <BButton
            pill
            class="w-100"
            variant="airline"
            :disabled="!(BUTTON_ACTION_PERMISSIONS?.[invoiceData?.invoiceStatus]?.[invoiceData?.releaseStatus]?.SIGN || invoiceData?.signStatus === 'UNSIGNED') || isLoading || !companyId"
            @click="onSignInvoiceHandle"
          >
            {{ $t('invoice.btn.sign') }}
          </BButton>
          <BButton
            pill
            variant="outline-secondary"
            @click="handleExit"
          >
            {{ $t('invoice.btn.exit') }}
          </BButton>
        </div>
      </BCol>
      <BCol
        cols="10"
        style="overflow: auto; max-height: 100vh"
      >
        <div class="p-1 border-1 border-dark">
          <VuePdfEmbed
            v-if="pdfData"
            ref="pdfFileRef"
            :source="pdfData"
          />
        </div>
      </BCol>
    </BRow>

    <InvoiceSignModal
      :invoice-data="invoiceData"
      :companyId="invoiceData?.company?.id ?? null"
      @refetch="fetchInvoicePdf"
    />
  </IAmOverlay>
</template>
<script>
import { ref, computed } from '@vue/composition-api'
import { BButton, BCol, BRow } from 'bootstrap-vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import upperFirst from 'lodash/upperFirst'

import { useRouter } from '@/@core/utils/utils'
import store from '@/store'
import useInvoiceHandle from '@/views/invoices/useInvoiceHandle'
import invoiceStoreModule from '@/views/invoices/invoiceStoreModule'

import { removeAccents } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    VuePdfEmbed,
    InvoiceSignModal: () => import('@/views/invoices/components/InvoiceSignModal.vue'),
  },
  setup(_, { root }) {
    const {
      INVOICE_STORE,
      getInvoicePdfById,
      loading,
      BUTTON_ACTION_PERMISSIONS,
      getInvoiceById,
      sendMailInvoice,
    } = useInvoiceHandle()
    const { router } = useRouter()
    const pdfFileRef = ref()
    const pdfData = ref()
    // Register module
    if (!store.hasModule(INVOICE_STORE)) store.registerModule(INVOICE_STORE, invoiceStoreModule)

    const invoiceData = ref()
    const loadingInitData = ref(false)
    const fetchInvoicePdf = async (id = router.currentRoute.params.id) => {
      loadingInitData.value = true
      const promiseArr = [getInvoicePdfById(id), getInvoiceById(id)]
      const res = await Promise.all(promiseArr)
      pdfData.value = URL.createObjectURL(res[0].data)
      invoiceData.value = res[1]
      loadingInitData.value = false
    }

    console.log('router', router.currentRoute.params.id)
    if (router.currentRoute.params.id) {
      fetchInvoicePdf(router.currentRoute.params.id)
    }

    function handleEditInvoice() {
      router.push({ name: 'apps-invoiceDetail', params: { id: router.currentRoute.params.id }, hash: '#edit' })
    }

    function handleExit() {
      router.push({ name: 'apps-invoiceList' })
    }

    async function onSendEmailHandle() {
      const invoiceId = invoiceData.value?.id
      if (!invoiceId) return
      await sendMailInvoice(invoiceId)
    }

    async function onSignInvoiceHandle() {
      root.$bvModal.show('modal-sign-invoice')
    }

    function onExportPDFHandle() {
      const buyerNameModify = upperFirst(removeAccents(invoiceData.value.buyerName).replace(/\s+/g, ''))
      const fileName = `${invoiceData.value.referenceCode}_${invoiceData.value.templateNumber}${invoiceData.value.symbol}_${invoiceData.value.invNumber}${buyerNameModify ? `_${buyerNameModify}` : ''}.pdf`
      pdfFileRef.value.download(fileName)
    }

    const isLoading = computed(() => loading.value || loadingInitData.value)
    const companyId = computed(() => store.getters['userStore/getAgencyCompany']?.id)
    return {
      handleEditInvoice,
      pdfData,
      handleExit,
      BUTTON_ACTION_PERMISSIONS,
      invoiceData,
      isLoading,
      onSendEmailHandle,
      onExportPDFHandle,
      onSignInvoiceHandle,
      pdfFileRef,
      fetchInvoicePdf,
      companyId,
    }
  },
}
</script>
<style lang="">

</style>
